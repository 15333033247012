
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import CSpinner from "@/components/Spinner.vue";

const VLogout = defineComponent({
  name: "VLogout",
  components: { CSpinner },
  setup() {
    const store = useStore();
    const router = useRouter();

    async function handleLogout() {
      await store.dispatch("deleteSession");
      router.replace({ name: "root" });

      setTimeout(() => router.go(0), 200);
    }

    handleLogout();
  },
});

export default VLogout;
